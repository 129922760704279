<template>
  <div class="d-flex justify-content-center pt-5 min-vh-100" style="background: #fff;">
    <b-card class="login-card w-100">
      <div class="flex-between-center">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
            <h2>Log in</h2>
            <div class="mt-3 w-100"></div>
            <form-group-input placeholder="No Hp" :need-label="false" label="Email" v-model="model.username" rules="required" id="username" />
            <div class="mt-3 w-100"></div>
            <form-group-input placeholder="Password anda" :need-label="false" label="Email" v-model="model.password" rules="required" type="password" id="password" />
            <!-- <b-row class="flex-between-center">
              <b-col cols="auto">
                <a class="fs--1" href="#">Forgot Password?</a>
              </b-col>
            </b-row> -->
            <div>
              <b-button type="submit" variant="primary" block class="w-100 mt-3"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Log in</b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-card>
  </div>
</template>

<script>
import FormGroupInput from '@/components/FormGroupInput.vue';
export default {
  components: {
    FormGroupInput
  },
  data () {
    return {
      isLoading: false,
      model: {
        username: localStorage.getItem("username"),
        password: null
      }
    }
  },
  methods: {
    onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      this.$store.dispatch('account/login', {
        username: this.model.username,
        password: this.model.password
      }).then(() => {
        if (this.$store.getters['account/accountData'].role !== 'agent') {
          alert('I\'m so sorry, You don\'t have permission to access on this website.')
          this.$store.dispatch('account/logout').then(() => {
            this.$router.push('/auth/login')
            location.reload()
          }).catch(() => {
            this.$router.push('/auth/login')
            location.reload()
          })
        } else {
          localStorage.setItem("username", this.model.username);
          // this.$store.dispatch('notification/welcome', this.$store.getters['account/accountData'].firstName)
          this.isLoading = false
          this.$router.push(this.$route.query.redirect !== undefined && !this.$route.query.redirect.includes('logout') ? this.$route.query.redirect : '/').then(() => {
            // location.reload()
          })
        }
      }).catch(error => {
        this.isLoading = false
        // this.$store.dispatch('notification/error', error)
        this.$store.dispatch('notification/error', 'Kombinasi no hp & password anda salah')
      })
    }
  }
}
</script>